.App-header {
  padding-top: 30px;
  text-align: center;
}
.App-info {
  text-align: center;
}
.App-btc-logo {
  padding: 15px;
  width: 100px;
}
table {
  font-size: x-large;
}
